.face-selector
{
    padding: 16px;
    text-align: left;
}

    .face-selector .mdhui-face-selector
    {
        text-align: left;
        margin-top: 8px;
    }

        .face-selector .mdhui-face-selector .mdhui-face
        {
            margin-left: 0;
            width: 32px;
            height: 32px;
        }

.metrics-selector
{
    border-top: solid 1px #eee;
    cursor: pointer;
    padding: 16px;
    padding-left: 48px;
    padding-right: 48px;
    position: relative;
}

    .metrics-selector .data-icon
    {
        position: absolute;
        left: 16px;
        top: calc(50% - 10px);
    }

    .metrics-selector .status-icon
    {
        position: absolute;
        right: 16px;
        top: calc(50% - 8px);
        color: #555;
    }

    .metrics-selector .data-type
    {
        font-weight: bold;
        font-size: .88em;
    }

    .metrics-selector .data-source
    {
        color: #555;
        font-size: .7em;
    }


.title
{
    padding: 16px;
    font-size: 1.1em;
    font-weight: bold;
    padding-bottom: 0;
}

