.pem-mini-day-bar-chart
{
    border-right: solid 1px #eee;
    text-align: center;
    height: 40px;
    padding-top: 172px;
    font-size: .7em;
    position: relative;
    cursor: pointer;
    background: #FFF;
}

    .pem-mini-day-bar-chart .day
    {
        padding-top: 4px;
        padding-bottom: 4px;
        height: 32px;
        line-height: 16px;
    }

    .pem-mini-day-bar-chart .badges
    {
        height: 50px;
        width: 100%;
        position: absolute;
        top: 124px;
        left: 0;
        display: flex;
        gap: 4px;
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 0px;
    }

        .pem-mini-day-bar-chart .badges .badge
        {
            width: 24px;
            height: 24px;
            text-align: center;
            font-size: 10px;
            border-radius: 12px;
            line-height: 24px;
            display: inline-block;
            flex-shrink: 0; /* do not shrink - initial value: 1 */
            flex-basis: 24px; /* width/height  - initial value: auto */
            position: relative;
            color: #FFF;
        }

            .pem-mini-day-bar-chart .badges .badge .chevron
            {
                position: absolute;
                right: -4px;
                top: -4px;
                border-radius: 32px;
                padding: 3px;
                background: linear-gradient(to bottom, #555 0%, #888 100%);
                font-size: .5em;
                font-weight: bold;
                text-align: center;
                min-width: 10px;
                user-select: none;
                font-size: .62em;
                line-height: 1;
                padding-left: 2px;
                padding-right: 1px;
            }

            .pem-mini-day-bar-chart .badges .badge .chevron-down
            {
                top: auto;
                bottom: -4px;
            }


    .pem-mini-day-bar-chart.selected
    {
        background: #EAF4FB;
    }

    .pem-mini-day-bar-chart .day-bar-wrapper
    {
        position: absolute;
        left: 8px;
        width: calc( 100% - 16px);
        height: calc(100% - 136px);
        top: 40px;
    }

    .pem-mini-day-bar-chart .face-wrapper
    {
        position: absolute;
        left: 0;
        top: 8px;
        text-align: center;
        width: 100%;
    }

        .pem-mini-day-bar-chart .face-wrapper .mdhui-face
        {
            width: 24px;
            height: 24px;
        }

    .pem-mini-day-bar-chart .bar
    {
        position: absolute;
        bottom: 0;
        z-index: 2;
    }

    .pem-mini-day-bar-chart .average-marker
    {
        border-top: dashed 1px #ddd;
        position: absolute;
        left: -8px;
        right: -8px;
        z-index: 1;
    }
