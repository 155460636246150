.week-in-review
{
    position: sticky;
    position: -webkit-sticky;
    top: env(safe-area-inset-top, 0);
    z-index: 1;
    margin-top: 0;
    box-shadow: 0px 2px 2px #ccc;
    background: #FFF;
}

    .week-in-review .title
    {
        padding: 8px;
        font-weight: bold;
        font-size: 15px;
        border-bottom: solid 1px #eee;
    }

        .week-in-review .title .legend
        {
            position: absolute;
            right: 8px;
            top: 8px;
            font-size: .7em;
            color: #555;
            font-weight: normal;
            line-height: 2em;
        }

            .week-in-review .title .legend .average-marker-representation
            {
                display: inline-block;
                vertical-align: middle;
                height: 1px;
                width: 16px;
                border-top: dashed 1px #ccc;
                margin-right: 8px;
            }

.week-navigator
{
    background: #eee;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    gap: 4px;
    padding: 8px;
    box-shadow: inset 0px -2px 2px #ddd;
    z-index: 0;
}

    .week-navigator .week-navigator-day
    {
        border: solid 1px #bbb;
        border-radius: 5px;
        overflow: hidden;
        flex-shrink: 0; /* do not shrink - initial value: 1 */
        flex-basis: 64px; /* width/height  - initial value: auto */
        position: relative;
        z-index: 1;
        height: 212px;
    }

        .week-navigator .week-navigator-day .mdhui-mini-day-bar-chart
        {
            width: 100% !important;
            display: block;
        }