.day-list-item .mdhui-card
{
    box-shadow: none;
}

.day-list-item .mdhui-action
{
    border-bottom: solid 1px #ddd;
    padding: 16px;
}

    .day-list-item .mdhui-action .title
    {
        font-weight: bold;
    }

    .day-list-item .mdhui-action .subtitle
    {
        color: #555;
        font-size: .88em;
    }

    .day-list-item .mdhui-action .mdhui-face
    {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 48px;
        top: 24px;
    }

.day-list-item.selected .mdhui-card
{
    box-shadow: 0px 9px 9px #ddd;
}

.day-list-item .face-selector
{
    padding: 16px;
    border-bottom: solid 1px #ddd;
    font-size: .88em;
}

.day-list-item .mdhui-face-selector
{
    padding-top: 8px;
    text-align: left;
}

    .day-list-item .mdhui-face-selector .mdhui-face
    {
        margin-left: 0;
        width: 32px;
        height: 32px;
    }

.day-list-item .day-metrics
{
    display: block;
    padding-bottom: 16px;
}

    .day-list-item .day-metrics .day-metric
    {
        display: inline-block;
        width: 50%;
    }

.day-list-item .highlight
{
    padding: 16px;
    padding-left: 64px;
}

    .day-list-item .highlight .badge
    {
        width: 32px;
        height: 32px;
        text-align: center;
        font-size: 12px;
        border-radius: 24px;
        line-height: 32px;
        display: inline-block;
        position: absolute;
        color: #FFF;
        left: 16px;
        top: calc(50% - 16px);
    }

        .day-list-item .highlight .badge .chevron
        {
            position: absolute;
            right: -6px;
            top: -6px;
            border-radius: 32px;
            padding: 3px;
            background: linear-gradient(to bottom, #555 0%, #888 100%);
            font-weight: bold;
            text-align: center;
            min-width: 10px;
            user-select: none;
            font-size: .8em;
            line-height: 1;
        }

        .day-list-item .highlight .badge .chevron-down
        {
            top: auto;
            bottom: -6px;
        }

    .day-list-item .highlight .highlight-description
    {
        font-size: .88em;
    }
