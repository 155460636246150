h1
{
    font-size: 1.5em;
    margin: 0;
}

.face-selector
{
    padding: 16px;
    text-align: left;
}

    .face-selector .mdhui-face-selector
    {
        text-align: left;
        margin-top: 8px;
    }

        .face-selector .mdhui-face-selector .mdhui-face
        {
            margin-left: 0;
            width: 32px;
            height: 32px;
        }

.metrics-selector
{
    border-top: solid 1px #eee;
    cursor: pointer;
    padding: 16px;
    padding-left: 48px;
    padding-right: 48px;
    position: relative;
}

    .metrics-selector .data-icon
    {
        position: absolute;
        left: 16px;
        top: calc(50% - 10px);
    }

    .metrics-selector .status-icon
    {
        position: absolute;
        right: 16px;
        top: calc(50% - 8px);
        color: #555;
    }

    .metrics-selector .data-type
    {
        font-weight: bold;
        font-size: .88em;
    }

    .metrics-selector .data-source
    {
        color: #555;
        font-size: .7em;
    }

.source-switcher
{
    padding: 16px;
    padding-bottom: 0;
}


.switchers
{
    padding: 16px;
}

.category-switcher
{
    margin-bottom: 16px;
    border: solid 1px #ddd;
    border-radius: 16px;
    display: flex;
    overflow: hidden;
}

    .category-switcher:last-child
    {
        margin: 0;
    }


    .category-switcher .category
    {
        padding: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        flex-grow: 1;
        text-align: center;
        border-left: solid 1px #ddd;
        cursor: pointer;
        color: #666;
        position: relative;
        font-size: .88em;
    }

        .category-switcher .category svg
        {
            margin-left: 4px;
            margin-right: 4px;
        }

        .category-switcher .category.selected
        {
            background: var(--color-primary);
            color: #FFF;
        }

        .category-switcher .category:first-child
        {
            border: none;
        }

.header
{
    background: #FFF;
}

    .header .title
    {
        padding: 16px;
        font-size: 1.1em;
        font-weight: bold;
        position: relative;
        z-index: 2;
        background: #FFF;
        box-shadow: 0px 2px 2px #ddd;
        position: sticky;
        top: env(safe-area-inset-top, 0);
    }

    .header .mdhui-segmented-control
    {
    }
